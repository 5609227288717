<template>
  <section
    v-bind:style="getStyle()"
    id="buttons-message"
    class="buttons-message"
  >
    <div class="buttons-message__wrapper">
      <vs-textarea
        v-if="!hideText"
        ref="textarea"
        type="text"
        :placeholder="
          lang.botMaker.botEditor.messageType.buttons.textarea[languageSelected]
        "
        v-model="buttonsText"
        @keyup="buttonsTextChange"
        counter="2000"
        :counter-danger.sync="counterDanger"
      />
      <div class="buttons">
        <Buttons
          ref="buttons"
          :isWidget="isWidget"
          :key="renderButtonsKey"
          @set_buttons="setButtons"
          @set_links="setLinks"
          @set_intents="setIntents"
          @addOptions="addOptions"
          :buttonsEntry="buttons"
          :linksEntry="links"
          :intentsEntry="intents"
          :buttonLabels="buttonLabels"
          :maxCharsButton="maxCharsButton"
        />
        <vs-button class="add-button" v-if="!hideAddOption" @click="addOption">
          +
          {{
            lang.botMaker.botEditor.messageType.buttons.addButton[
              languageSelected
            ]
          }}
        </vs-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Buttons from './Buttons.vue'
export default {
  props: {
    hasErrors: Boolean,
    hideText: {
      type: Boolean,
      default: false
    },
    buttonLabels: {
      type: String,
      default: ''
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    maxCharsButton: {
      type: Number,
      default: 20
    }
  },
  components: {
    Buttons
  },
  data() {
    return {
      links: {},
      intents: {},
      renderButtonsKey: 0,
      savedState: null,
      counterDanger: false,
      buttonsText: '',
      buttons: []
    }
  },
  watch: {
    buttons: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        this.$emit('onTextChangeWidget', {
          buttonsText: this.buttonsText,
          buttons: this.buttons
        })
      }
    },
    botMessageInEdit() {
      if (this.botMessageInEdit.type.isButtonsMessage()) {
        this.updateButtonsMsgView()
      } else {
        this.buttonsText = this.botMessageInEdit.type.name
        this.buttons = ['', '', '']
        this.reRenderButtons()
      }
    },
    counterDanger() {
      this.$emit('onError')
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botMessageInEdit']),
    hideAddOption() {
      return this.buttons.filter(b => b === '').length
    }
  },
  methods: {
    getStyle() {
      if (this.isWidget) return 'background-color: transparent!important;'
    },
    ...mapMutations('botMaker', ['SET_BOT_MESSAGE_BUTTONS']),
    setButtons(newButtons) {
      this.buttons = newButtons
    },
    setLinks(newLinks) {
      this.links = newLinks
    },
    setIntents(newIntents) {
      this.intents = newIntents
    },
    addOptions() {
      let formattedButtons = []
      const links = this.links
      const intents = this.intents
      /** Message Buttons Loop and send new Formatted */
      this.buttons.forEach((button, index) => {
        let toPush
        if (button) {
          if (links && links[index]) {
            toPush = { type: 'url', text: button, payload: links[index] }
          } else if (intents && intents[index]) {
            toPush = { type: 'button', text: button, payload: intents[index] }
          } else {
            toPush = {
              type: 'button',
              text: button,
              payload: `${button}`
            }
          }
          formattedButtons.push(toPush)
        }
      })
      if (!this.isWidget) {
        if (formattedButtons.length) {
          if (!this.botMessageInEdit.type.hasOwnProperty('setMessage')) {
            this.botMessageInEdit.type.setMessage = buttons => {
              this.botMessageInEdit.type.responseOptions = buttons
            }
          }
          this.botMessageInEdit.type.setMessage(formattedButtons)
        } else {
          this.botMessageInEdit.type.setMessage([])
        }
      }
    },
    addOption() {
      this.buttons.push('')
      this.reRenderButtons()
      this.$nextTick(() => {
        const index = this.buttons.length - 1
        this.focusButton(index)
      })
    },
    buttonsTextChange() {
      // if (this.botMessageInEdit.type.isButtonsMessage()) {
      //   this.$emit('onTextChange', this.buttonsText)
      // }
      if (!this.isWidget) {
        this.botMessageInEdit.type.name = this.buttonsText
      } else {
        this.$emit('onTextChangeWidget', {
          buttonsText: this.buttonsText,
          buttons: this.buttons
        })
      }
    },
    reset() {
      if (this.savedState) {
        this.SET_BOT_MESSAGE_BUTTONS(this.savedState)
        this.reload(
          this.botMessageInEdit.type.name,
          this.botMessageInEdit.type.responseOptions
        )
      } else {
        this.SET_BOT_MESSAGE_BUTTONS({
          name: '',
          responseOptions: ['', '', '']
        })
        this.buttonsText = ''
        this.buttons = ['', '', '']
        this.reRenderButtons()
      }
    },
    reload(name, responseOptions) {
      this.buttonsText = name
      this.buttons = []
      responseOptions.forEach(option => {
        this.buttons.push(option.text)
        if (option.type == 'url') {
          this.links[this.buttons.length - 1] = option.payload
        } else if (option.payload !== option.text) {
          this.intents[this.buttons.length - 1] = option.payload
        }
      })
      this.reRenderButtons()
    },
    loadButtons({ context, text }) {
      if (context) {
        if (context.values && context.values.length) {
          this.buttons = context.values.map(function(el) {
            return el.text
          })
        } else {
          this.buttons = ['', '', '']
        }
      }
      if (text && text.length && text[0] && typeof text[0] === 'string') {
        this.buttonsText = text[0]
      } else {
        this.buttonsText = ''
      }
      this.reRenderButtons()
    },
    reRenderButtons() {
      this.renderButtonsKey++
    },
    resetButtons() {
      this.buttonsText = ''
      this.buttons = ['', '', '']
      this.reRenderButtons()
    },
    updateButtonsMsgView() {
      if (this.botMessageInEdit.isEditing) {
        this.reload(
          this.botMessageInEdit.type.name,
          this.botMessageInEdit.type.responseOptions
        )
        if (!this.savedState) {
          this.savedState = JSON.parse(
            JSON.stringify(this.botMessageInEdit.type)
          )
        }
      } else {
        this.buttonsText = ''
        this.buttons = ['', '', '']
        /** Render ButtonsKey */
        this.reRenderButtons()
      }
    },
    focusButton(index = 0) {
      this.$refs.buttons.focusButton(index)
    }
  },
  mounted() {
    if (this.isWidget) {
      this.buttons = ['', '', '']
      this.reRenderButtons()
    } else if (this.botMessageInEdit.type.isButtonsMessage()) {
      this.updateButtonsMsgView()
    } else {
      this.buttons = ['', '', '']
      /** Render ButtonsKey */
      this.reRenderButtons()
    }
  }
}
</script>

<style lang="scss">
.buttons-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  &__wrapper {
    max-width: 230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .vs-con-textarea {
      border: none;
      margin-bottom: 0;

      textarea {
        overflow: hidden;
        overflow-wrap: break-word;
        resize: none;
        height: 76px;
        border-radius: 20px 20px 0 0;
        border: none;
        padding: 15px 15px 0 15px;
        background-color: #f0f0f0;
        white-space: pre-wrap;
        word-break: break-word;
        word-wrap: break-word;
      }

      &.focusx {
        transform: none;
        box-shadow: none;
      }

      .vs-textarea--count {
        border-radius: 5px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      padding: 15px;
      background-color: #f0f0f0;
      border-radius: 0 0 20px 20px;

      .vs-con-input-label {
        max-width: 100%;
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      .vs-input--placeholder {
        text-align: center;
      }

      input {
        padding: 10px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        border: none;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      .add-button {
        margin-top: 10px;
      }
    }
  }
}
</style>
