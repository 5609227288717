<template>
  <div>
    <div class="d-flex mb-3" v-for="(button, index) in buttons" :key="index">
      <vs-input
        :label="buttonLabels ? buttonLabels + ' ' + (index + 1) : ''"
        :key="index"
        :placeholder="
          `${
            lang.botMaker.botEditor.messageType.buttons.textarea[
              languageSelected
            ]
          } ${index + 1}`
        "
        v-model="buttons[index]"
        @change="addOptions"
        @keyup="addOptions"
        :ref="`button-${index}`"
        :maxlength="maxCharsButton"
      />
      <LinkButton
        v-if="!isWidget"
        @set_link="setLink"
        :index="index"
        :key="dateKey + index"
        :link="links[index]"
        :intent="intents[index]"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LinkButton from './LinkButton.vue'
export default {
  name: 'Buttons',
  components: {
    LinkButton
  },
  props: {
    buttonLabels: {
      type: String,
      default: ''
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    buttonsEntry: {
      type: Array,
      default: function() {
        return []
      }
    },
    linksEntry: {
      type: Object,
      default: null
    },
    intentsEntry: {
      type: Object,
      default: null
    },
    maxCharsButton: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      dateKey: new Date().getTime(),
      currentLink: '',
      isPromptActive: false,
      buttons: [],
      intents: [],
      selIndex: 0, // Selected Index from Butttons
      links: {} // Object with links using button index as keys
    }
  },
  beforeMount() {
    this.buttons = [...this.buttonsEntry]
    if (this.linksEntry) this.links = { ...this.linksEntry }
    if (this.intentsEntry) this.intents = { ...this.intentsEntry }
  },
  methods: {
    setLink([index, link, intent]) {
      this.links[index] = link
      this.intents[index] = intent
      this.$emit('set_links', { ...this.links })
      this.$emit('set_intents', { ...this.intents })
      this.$emit('addOptions')
      this.dateKey = new Date().getTime()
    },
    addOptions() {
      this.$emit('set_buttons', [...this.buttons])
      this.$emit('set_links', { ...this.links })
      this.$emit('set_intents', { ...this.intents })
      this.$emit('addOptions')
    },
    focusButton(index = 0) {
      this.$nextTick(() => {
        if (this.$refs[`button-${index}`]) {
          const lastButton = this.$refs[`button-${index}`][0]
          lastButton.$el.children[0].querySelector('input').focus()
        }
      })
    }
  },
  computed: {
    titlePrompt() {
      return `${
        this.lang.botMaker.botEditor.messageType.buttons.check[
          this.languageSelected
        ]
      } - ${
        this.lang.botMaker.botEditor.messageType.buttons.textarea[
          this.languageSelected
        ]
      } - ${
        this.lang.botMaker.botEditor.messageType.buttons.textarea[
          this.languageSelected
        ]
      } ${this.selIndex + 1}`
    },
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botMessageInEdit'])
  }
}
</script>

<style lang="scss">
.vs-button-primary.vs-button-border,
.vs-button-primary.vs-button-flat {
  &.buttons_link_completed {
    background: #ff9f43 !important;
    color: white !important;
  }
}
</style>
