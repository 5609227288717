<template>
  <div class="konecta_link_button">
    <vs-button
      v-bind:class="{ buttons_link_completed: link }"
      @click="openDialog(index)"
      icon="settings"
      color="primary"
      type="border"
    ></vs-button>
    <vs-prompt
      :vs-title="titlePrompt"
      :vs-active.sync="isPromptActive"
      :vs-accept-text="lang.botMaker.integrations.accept[languageSelected]"
      :vs-cancel-text="lang.botMaker.cancel[languageSelected]"
      vs-button-cancel="filled"
      :is-valid="isValidUrlC"
      @vs-accept="saveLink"
      @vs-close="isPromptActive = false"
      @vs-cancel="isPromptActive = false"
    >
      <div>
        <label>{{
          lang.botMaker.botEditor.messageType.buttons.isLink[languageSelected]
        }}</label>
        <vx-slider v-model="isLink" />
      </div>
      <div class="mt-3">
        <label for="">{{
          isLink
            ? lang.botMaker.botEditor.messageType.buttons.link[languageSelected]
            : lang.botMaker.botEditor.messageType.buttons.intent[
                languageSelected
              ]
        }}</label>
        <vs-input :placeholder="placeholder" v-model="currentPayload" />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LinkButton',
  props: {
    index: {
      type: Number,
      required: true
    },
    link: {
      type: String,
      default: ''
    },
    intent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLink: false,
      currentPayload: '',
      isPromptActive: false
    }
  },
  methods: {
    isValidUrl(string) {
      if (!string) return false
      try {
        new URL(string)
      } catch (_) {
        return false
      }

      return true
    },
    openDialog() {
      this.isPromptActive = true
      if (this.link) {
        this.isLink = true
        this.currentPayload = this.link ? this.link : ''
      } else {
        this.currentPayload = this.intent ? this.intent : ''
      }
    },
    saveLink() {
      let currentIntent = ''
      let currentLink = ''
      this.currentPayload = this.currentPayload.trim()
      if (this.isLink) {
        currentLink = this.currentPayload
      } else {
        currentIntent = this.currentPayload
      }
      this.$nextTick(() => {
        this.$emit('set_link', [this.index, currentLink, currentIntent])
      })
    }
  },
  computed: {
    placeholder() {
      if (this.isLink) {
        return 'http://example.com'
      } else {
        return ''
      }
    },
    titlePrompt() {
      return `${
        this.lang.botMaker.botEditor.messageType.buttons.check[
          this.languageSelected
        ]
      } - ${this.index}`
    },
    isValidUrlC() {
      return this.isValidUrl(this.currentLink)
    },
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botMessageInEdit'])
  }
}
</script>
